@import "../../sass/abstracts/";

// FOOTER 
.footer {
    position: relative;
    z-index: 8;
    font-size: 18px;
    max-width: 1833px;
    margin: auto;

    .footer-line {
        width: 91.666%;
        margin: auto;
    }

    &-body {
        margin: 21px auto;
        margin-bottom: 0;
        max-width: $full_max_width;
        width: 91.666%;
    
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-left {
            flex: 1 1 48%;
            display: flex;
            align-items: flex-start;
            justify-content: left;
            margin-right: 10px;
            margin: 2% 0 5%;

            div {
                display: flex;
                align-items: center;
                justify-content: left;
                @include tablet {
                    justify-content: center;
                }
            }
            
            
            img {
                width: 100%;
                padding-right: 10px;
                max-width: 384px;
            }
            h3 {
                color: #5271FF;

                
                font-weight: 600;
                
                @include small_desktop {
                    font-size: 32px;
                }
                @include tablet {
                    font-size: 36px;
                }
                @include medium_phone {
                    font-size: 24px;
                }
                @include small_phone {
                    font-size: 18px;
                }
            }
        }

        &-right {
            flex: 1 1 500px;
            flex-basis: 50%;

            @include tablet {
                flex-basis: unset;
            }
            display: flex;
            justify-content: space-between;
            

            h6 {
                margin-bottom: 16px;
                font-size: 1em;
                padding-top: 1em;
                text-transform: uppercase;
            }

            a {
                text-decoration: none;
                color: $black;
            }

            ul {
                margin-right: 10px;
                list-style: none;
                
                li {
                    margin-bottom: 12px;
                    font-size: 0.9em;
                }
            }
        }

        @include tablet {

            &-right {
                flex-wrap: wrap;
                
                > *:nth-child(1) {
                    order: 2;
                }
            }
        }
    }
    
    &-follow-us {
        width: 91.666%;
        margin: 21px auto;
        

        div {
            display: flex;
            align-items: center;
            float: right;
            flex-direction: row;
            flex-wrap: wrap;

            > * {
                margin-left: 18px;
            }

            p {
                font-size: 1em;
            }

            img {
                border-radius: 50%;
                transition: 0.5s ease-in-out;

                &:hover {
                    border-radius: 20%;
                    box-shadow: 0 0 2px 0 $black;
                }

                @include tablet {
                    width: 30px !important;
                    height: 30px !important;
                }
                @include small_phone {
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }
    }

    &-bottom {
        margin-top: 160px;
        margin-bottom: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        
        .text {
            a {
                text-decoration: none;
                color: $black;
            }

            p {
                display: inline;
                font-size: 0.8em;
                font-weight: 400;
            }
        }
    }

    @include small_desktop {
        font-size: 16px;
    }
    @include tablet {
        font-size: 15px;
    }
    @include medium_phone {
        font-size: 14px;
    }
    @include small_phone {
        font-size: 13px;
    }
}