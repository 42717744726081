@import '../../sass/abstracts/';

.body-h-body {
    position: relative;
    z-index: 8;
    &-title {
        max-width: $normal_max_width;
        width: 91.666vw;
        margin: auto;
        h2 {
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .body-h {
        margin: 80px auto;
        max-width: $normal_max_width;
        width: 91.666vw;
        display: flex;
        justify-content: space-between;

        @include tablet {
            flex-wrap: wrap;
        }
        

        @supports (gap: 1em) {
            gap: 2em;
        }
        
        &-col {
            flex: 1 0 31%;

            @include tablet {
                min-width: 100%;
            }
            

            .img {
                margin-bottom: 0.5em;
                position: relative;
                img {
                    width: 100%;
                }
                h3 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    padding: 8px 12px;
                    background-color: $white_op70;
                    border: 3px solid $black;
                    border-radius: 5px;
                }
            }
            .bottomtext {
                padding: 1em;
                p {
                    margin-bottom: 0.5em;
                    line-height: 1.25em;
                }
                ul {
                    margin-bottom: 0.5em;
                    margin-left: 2em;
                }
            }
        }

        &-col ~ .body-h-col {
            margin-left: 2em;

            @include tablet {
                margin-left: 0;
            }

            @supports (gap: 1em) {
                margin-left: 0;
            }
        }
    }
}