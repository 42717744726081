@import '/src/sass/abstracts/colors';
@import '/src/sass/abstracts/fonts';
@import '/src/sass/abstracts/sizes';
@import '/src/sass/abstracts/mixins';
@import '/src/sass/components/globals';

.menu-open-enter {
    transform: translateY(-101%);
}
.menu-open-enter-active {
    transform: translateY(0%);
    transition: all var(-speed) ease;
}
.menu-open-exit {
    transform: translateY(0%);
}
.menu-open-exit-active {
    transform: translateY(-101%);
    transition: all var(-speed) ease;
}

.js-black-clr-invert {
    color: $white !important;
}
.js-white-clr-invert {
    color: $black;
}
.js-black-bgr-invert {
    background-color: $white;
}
.js-white-bgr-invert {
    background-color: $black;
}
.js-black-bdr-invert {
    border-color: $white !important;
}
.js-white-bdr-invert {
    border-color: $black;
}
.js-textshadow-white {

    -webkit-animation: textshadow 10s ease-in-out infinite;
    -moz-animation: textshadow 10s ease-in-out infinite;
    animation: textshadow 10s ease-in-out infinite;

    @keyframes textshadow { 
        0%{text-shadow: -10px 0 10px $white}
        25%{text-shadow: 0 0 5px $white}
        50%{text-shadow: 10px 0 50px $white}
        75%{text-shadow: 0 0 100px $white}
    }
}


.js-black-clr-invert,
.js-white-clr-invert,
.js-black-bgr-invert,
.js-white-bgr-invert,
.js-black-bdr-invert,
.js-white-bdr-invert {
    transition: 0.5s ease-in;
}


.fill2 {
    max-width: 160px;
    width: 8.333vw;
    flex-basis: 50%;
    align-items: center;
    

    i {
        padding: 5px 5px;
        &:hover {
            cursor: pointer;
        }

        @include medium_phone {
            padding: 2px;
        }
    }
}
.fill3 {
    max-width: 240px;
    width: 12.5vw;
    flex-basis: 66.66%;
}
.fill4 {
    max-width: 320px;
    width: 16.666vw;
    flex-basis: 100%;
}



// HEADER
:root {
    --white: white;
    --whiteshadow: white;
    --black: rgba(0,0,0,0.2);
}

.header {
    position: fixed;
    top: 0;
    left: 0;    
    z-index: 900;
    width: 100vw;
    transition: 0.1s;
    background-color: rgba(255,255,255,0);
}

.header-scrolled {
    background-color: rgba(255,255,255,1);
    --white: black;
    --whiteshadow: rgba(0,0,0,0.2);
    --black: rgba(0,0,0,0.05);
}


.header-box {
    margin: auto;
    display: flex;
    justify-content: space-around;

    &-width {
        width: 100%;
        font-family: 'roboto';
        max-width: 1600px;

        .right, .left {
            flex-basis: 100%;
            justify-content: space-evenly;
        }

        
    }
    
    nav, ul {
        display: flex;
        padding-left: 0;
        align-items: center;
        margin: auto;
        
        li {
            font-size: 17px;
            font-weight: 300;
            letter-spacing: 2px;
            list-style: none;
            text-align: center;
            padding-top: 40px;
            padding-bottom: 50px;
            display: flex;
            justify-content: center;

            @include desktop {
                font-size: 15px;
            }
            @include small_desktop {
                font-size: 12px;
            }

            @include medium_phone {
                font-size: 11px;
            }
        }
        .left a, .right a {
            text-decoration: none;
            color: black !important;
            text-transform: uppercase;
            padding: 10px 5px;
            position: relative;
            transition: 0.6s;

            &:hover {
                text-shadow: 0 0 10px var(--black);
                
            }
            &::before {
                content: '';
                position: absolute;
                transition: transform ease-out 0.3s;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-right: 2px solid $black;
                border-left: 2px solid $black;
                transform: scale(1,0);
            }
            &::after {
                content: '';
                position: absolute;
                transition: transform ease-out 0.3s;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-top: 1px solid var(--white);
                border-bottom: 1px solid var(--white);
                transform: scale(0,1);
                box-shadow: 
                    inset 0px 11px 8px -10px var(--whiteshadow),
                    inset 0px -11px 8px -10px var(--whiteshadow); 
            }
            &:hover::before {
                transform: scale(1,0.05);
            }
            &:hover::after {
                transform: scale(1.05,1);
            }
            
        }
        
    }

    

    .logo {
        text-align: center;
        max-width: 640px;
        width: 33.333vw;
        padding: 2px 0;

        img {
            width: 100px;
            
            @include desktop {
                width: 90px;
            }
            @include small_desktop {
                width: 70px;
                padding: 0px;
            }
            @include tablet {
                width: 65px;
            }

            @include medium_phone {
                width: 70px;
                padding: 0 10px;
            }
        }

        
    }
    
    .menu, .menu-content {
        display: none;

        
    }
    .menu {
        order: 1;
        align-items: center;
        justify-content: center;
        font-size: 1.0em;

        svg {
            width: 1.5rem;
        }
    }

    .logo, .menu {
        flex-basis: 50%;
    }

    @include tablet {
        background-color: rgba(255,255,255,1) !important;

        .left {
            order: 1;

            
        }
        .menu {
            display: flex;
            max-width: 150px;
            color: $black !important;
        }
        .samarbetspartners, .media, .om-oss {
            display: none;
        }
        .left li, .right li {
            padding: 0;
        }
    }

    @include medium_phone {
        .logo, .menu {
            flex-basis: 40%;
            
        }

        .left li, .right li {
            padding: 0;
        }
    }

    @media (max-width: 290px) {
        .right, .kontakt {
            display: none;
        }
        .logo, .menu {
            flex-basis: 30%;
            
        }
    }
}

.white a {
    background-color: $white_op50;
    border-radius: 5px;
}

//MENU DROP DOWN - DROPDOWN MENU CONTENT
.menu-open {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    transition: 500ms ease-in-out;
}


.menu-content {
    
    
    width: 100%;
    min-height: 50vh;
    background-color: $black;
    color: white;
    overflow-y: scroll;
    transition: 0.33s ease-out;
    padding-bottom: 12px;
    border-radius: 5px;
    
   

    ul {
        width: 91.666%;
        margin: auto;
        list-style: none;
        

        li {
            padding: 16px 20px;
            margin: 20px 0;
            background-color: $white_op10;
            font-weight: 600;
            border-radius: 2%;

            a {
                font-size: 0.9em;
                color: white;
                text-decoration: none;
                padding: 5px 5px;
            }
            i, svg {
                float: right;
                padding: 2px 0 2px 50%;

                height: 1.5rem;
                justify-content: center;
            }

            .sub-menu-content {

                a {
                    color: $white;
                    font-weight: 400;
                    font-size: 0.8em;
                }
            }
            
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu {
        position: absolute;
        top: 18px;
        right: 4%;

        svg {
            height: 1.5em;
        }

        
    }

    img {
        width: 100%; 
        padding: 16% 10% 3%;
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
}