@import "../../../sass/abstracts/";


.policy-head {
    width: 100%;
    z-index: 1;
    position: relative;
    height: 30vh;
    max-width: 100%;
    
    display: block;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;

    @include large-height {
        height: 50vh;
    }

    

    .img {
        height: 50vh;
        width: 100vw;

        img {
            object-fit: cover;
            width: 100vw;
            filter: grayscale(30%);
            -webkit-filter: grayscale(30%);

            @include medium-height {
                width: 100vw;
            }

        }
    }
    .title {
        position: absolute;
        top: 66%;
        left: 50%;
        transform: translate(-50%,-50%);

        padding: 12px 20px;
        background-color: $lightlightblue;
        border-radius: 5px;
    }
}

.policy {
    margin: 40px auto 160px;
    max-width: $normal_max_width;
    width: 91.666vw;

    div {
       margin: 2em 0 1em;
        h4 {
            text-transform: uppercase;
            font-weight: 600;
        }
        p {
            line-height: 1.5em;
            margin: 1em 0;
        }

        div {
            width: 75%;
            margin: 2em auto 1em;

            h5 {
                font-style: italic;
                font-weight: 400;

            }

            @include medium_phone {
                width: 90%;
            }
        }
    }
}