@import '../../sass/abstracts/';

.body-a {
    margin: 80px auto;
    max-width: $full_max_width;
    width: 83.333vw;
    display: flex;
    align-items: center;
    justify-content:space-between;
    position: relative;
    z-index: 5;
    

    &-left {
        width: 45%;
        
        img {
            width: 100%;
        }
    }
    &-right {
        width: 45%;
        padding-right: 5%;
        
        h4 {
            margin-bottom: 21px;
        }
        p > * {
            margin-bottom: 48px;
        }
    }

    @include desktop {
        &-left, &-right {
            width: 100%;
            padding: 0;
        }
        &-left {
            margin-right: 10%;
        }
        
    }

    @include tablet {
        margin: 10px auto;
        flex-direction: column;
        &-left {
            margin-right: 0%;
        }
    }
}