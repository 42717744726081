@import "../../../sass/abstracts";
@import "../AboutUsPage/about-us-page.scss";

.om-oss-page {
    .title-var1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;

        border-radius: 10px;

        background-color: $deepblue_op80;
        color: $white;
        padding: 8px 0px;

        p {
            
            font-size: 130%;
            line-height: 1.5em;

            @include tablet {
                font-size: 100%;
            }
            @include medium_phone {
                width: 75vw;
            }
            @include small_phone {
                width: 85vw;
            }
        }

        > * {
            margin-bottom: 20px;
        }
    }
}
.om-oss-page-menu {
    margin-top: 0;
    

    ul {
        border: none;
        border-bottom: 1px solid $black_op20;
        white-space: unset;
        overflow: auto;
        flex-wrap: wrap;

        @include medium_phone {
            justify-content: center;
        }

        li {
            padding: 6px 1em !important;
            
            p {
                @include medium_phone {
                    text-align: center;
                }
            }
        }
    }
}

.body-b-body {
    margin-bottom: 180px;

    @include desktop {
        margin-bottom: 150px;
    }
    @include tablet {
        margin-bottom: 80px;
    }
    @include medium_phone {
        margin-bottom: 60px;
    }
    @include small_phone {
        margin-bottom: 40px;
    }
}

.body-g .title div {
    background: linear-gradient(130deg, $white_op10, $white_op50, $white_op10);
    background-size: 200% 200%;

    -webkit-animation: colorGradAnimation 5s ease 2s infinite;
    -moz-animation: colorGradAnimation 5s ease 2s infinite;
    animation: colorGradAnimation 5s ease 2s infinite;
}

@-webkit-keyframes colorGradAnimation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes colorGradAnimation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes colorGradAnimation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}