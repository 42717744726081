@import '../../sass/abstracts/';

.body-b {
    margin: 80px auto;
    max-width: $full_max_width;
    width: 83.333vw;

    display: flex;
    align-items: center;
    justify-content:space-between;

    position: relative;
    z-index: 5;

    &-title {
        display: block;
    }

    &-left {
        float: left;
        width: 45%;
        padding-left: 5%;
        margin-bottom: 15px;
        
        h4 {
            margin-bottom: 21px;
        }
        p > * {
            margin-bottom: 48px;
        }
        
    }
    &-right {
        float: right;
        width: 45%;

        img {
            width: 100%;
            position: relative;
        }
    }

    @include desktop {

        &-left {
            width: 100%;
            margin-right: 10%;
            padding: 0;
        }
        &-right {
            width: 100%;
        }
    }

    @include tablet {
        margin: 10px auto;
        flex-direction: column-reverse !important;
    }
}