@import '../../sass/abstracts/';

.body-e-body {
    position: relative;
    z-index: 8;

    .body-e-title {
        width: 91.666vw;
        max-width: $full_max_width;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        &-box {
            text-align: right;
        }

        h2 {
            font-weight: 400;
            line-height: 2em;
            margin-bottom: 18px;
        }
        p {
            font-size: 1.25em;
            line-height: 1.5em;
            max-width: 1034px;

            @include tablet {
                font-size: 1.1em;
            }
            @include medium_phone {
                font-size: 0.9em;
            }
            @include small_phone {
                font-size: 0.8em;
            }
        }
    }

    .body-e {
        margin: 80px auto;
        max-width: $full_max_width;
        width: 91.666vw;
    
        display: flex;
        align-items: center;
        justify-content:space-between;
        
    
        &-col {
            margin: 10px 0;
            width: 100%;
            border: 1px solid $black_op20;
            
            img {
                width: 100%;
                aspect-ratio: 4 / 3;
                object-fit: cover;
            }

            .text-col {
                
                padding: 48px 4.166vw;
                padding-bottom: 10px;

                h4 {
                    margin-bottom: 24px;
                }
                p {
                    line-height: 1.9em;
                }
            }


            .cta-btn3 {
                width: 100%;
                

                p {
                    float: right;
                    padding: 12px;
                }
            }

        }
        &-col ~ .body-e-col {
            
            margin-left: 8.333vw;
        }
    }
}