@import '../abstracts/mixins';


//FONT SIZES
$h1_fontsize: 64px;
$h2_fontsize: 48px;
$h3_fontsize: 36px;
$h4_fontsize: 24px;
$h5_fontsize: 21px;
$h6_fontsize: 18px;

$p_large_FS: 21px;
$p_medium_FS: 18px;
$p_small_FS: 16px;
$p_xsmall_FS: 16px;


h1 {
    font-size: $h1_fontsize;
    font-weight: 800;
    letter-spacing: -0.015em;
}
h2 {
    font-size: $h2_fontsize;
    font-weight: 700;
    letter-spacing: -0.015em;
}
h3 {
    font-size: $h3_fontsize;
    font-weight: 700;
    letter-spacing: -0.015em;

}
h4 {
    font-size: $h4_fontsize;
    font-weight: 700;
    letter-spacing: -0.015em;
}
h5 {
    font-size: $h5_fontsize;
    font-weight: 300;
    letter-spacing: -0.015em;
}
h6 {
    font-size: $h6_fontsize;
    font-weight: 700;
    letter-spacing: -0.015em;
}
p, .p {
    font-size: $p_large_FS;
    font-weight: 400;
    letter-spacing: -0.015em;
    
}
small {
    font-size: $p_small_FS;
    font-weight: 400;
    letter-spacing: -0.015em;
}



// ------- MEDIA QUERYS --------

@include desktop {
    h1 {
        font-size: calc(#{$h1_fontsize} * 0.8);
    }
    h2 {
        font-size: calc(#{$h2_fontsize} * 0.8);
    }
    h3 {
        font-size: calc(#{$h3_fontsize} * 0.8);
    }
    h4 {
        font-size: calc(#{$h4_fontsize} * 0.8);

    }
    h5 {
        font-size: calc(#{$h5_fontsize} * 0.8);

    }
    h6 {
        font-size: calc(#{$h6_fontsize});

    }
    p, .p {
        font-size: calc(#{$p_large_FS} * 0.8);

    }
    small {
        font-size: calc(#{$p_small_FS} * 0.9);

    }
}

@include small_desktop {
    h1 {
        font-size: calc(#{$h1_fontsize} * 0.7);
    }
    h2 {
        font-size: calc(#{$h2_fontsize} * 0.75);
    }
    h3 {
        font-size: calc(#{$h3_fontsize} * 0.75);
    }
    h4 {
        font-size: calc(#{$h4_fontsize} * 0.8);

    }
    h5 {
        font-size: calc(#{$h5_fontsize} * 0.8);

    }
    h6 {
        font-size: calc(#{$h6_fontsize} * 0.8);

    }
    p, .p {
        font-size: calc(#{$p_large_FS} * 0.7);

    }
    small {
        font-size: calc(#{$p_small_FS} * 0.8);

    }
}

@include tablet {
    h1 {
        font-size: calc(#{$h1_fontsize} * 0.6);
    }
    h2 {
        font-size: calc(#{$h2_fontsize} * 0.7);
    }
    h3 {
        font-size: calc(#{$h3_fontsize} * 0.7);
    }
    h4 {
        font-size: calc(#{$h4_fontsize} * 0.75);

    }
    h5 {
        font-size: calc(#{$h5_fontsize} * 0.75);

    }
    h6 {
        font-size: calc(#{$h6_fontsize} * 0.8);

    }
    p, .p {
        font-size: calc(#{$p_large_FS} * 0.6);

    }
    small {
        font-size: calc(#{$p_small_FS} * 0.7);

    }
}

@include medium_phone {
    h1 {
        font-size: calc(#{$h1_fontsize} * 0.5);
    }
    h2 {
        font-size: calc(#{$h2_fontsize} * 0.6);
    }
    h3 {
        font-size: calc(#{$h3_fontsize} * 0.6);
    }
    h4 {
        font-size: calc(#{$h4_fontsize} * 0.7);

    }
    h5 {
        font-size: calc(#{$h5_fontsize} * 0.7);

    }
    h6 {
        font-size: calc(#{$h6_fontsize} * 0.75);

    }
    p, .p {
        font-size: calc(#{$p_large_FS} * 0.55);

    }
    small {
        font-size: calc(#{$p_small_FS} * 0.65);

    }
}



@include small_phone {
    h1 {
        font-size: calc(#{$h1_fontsize} * 0.4);
    }
    h2 {
        font-size: calc(#{$h2_fontsize} * 0.5);
    }
    h3 {
        font-size: calc(#{$h3_fontsize} * 0.5);
    }
    h4 {
        font-size: calc(#{$h4_fontsize} * 0.6);

    }
    h5 {
        font-size: calc(#{$h5_fontsize} * 0.6);

    }
    h6 {
        font-size: calc(#{$h6_fontsize} * 0.6);

    }
    p, .p {
        font-size: calc(#{$p_large_FS} * 0.5);

    }
    small {
        font-size: calc(#{$p_small_FS} * 0.6);

    }
}










