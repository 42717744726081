@import "../../../sass/abstracts/";
@import "../AboutUsPage/about-us-page.scss";

.om-oss-page {
    .title-var1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;

        border-radius: 10px;

        background-color: $deepblue_op80;
        padding: 8px 0px;
        color: $white;
        p {
            
            font-size: 130%;
            line-height: 1.5em;

            @include tablet {
                font-size: 100%;
            }
            @include medium_phone {
                width: 75vw;
            }
            @include small_phone {
                width: 85vw;
            }
        }

        > * {
            margin-bottom: 20px;
        }
    }
}

.about-us {
    width: 91.666vw;
    max-width: $normal_max_width;
    margin: 100px auto 0px;

    @include tablet {
        margin: 40px auto 0px;
    }
    @include medium_phone {
        margin: 30px auto 0px;
    }
    .team {
        .title {
            margin: auto;
            h3 {
                margin: 50px 0;
                text-align: center;
                font-weight: 400;

                @include tablet {
                    margin: 40px 0;
                }
                @include medium_phone {
                    margin: 20px 0;
                }
            }
        }

        &-flex {
            margin: 80px auto 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            @include tablet {
                margin: 40px auto 0px;
            }
            @include medium_phone {
                margin: 30px auto 0px;
            }
            

            @supports (gap: 1em) {
                gap: 5%
            }

            .team-box {
                border: 1px solid $black_op20;
                flex: 1 1 47.5%;
                min-width: 35ch;

                @media (max-width: 52ch) {
                    min-width: unset;
                }

                margin-bottom: 7.5%;
            
                &-top {
                    width: 100%;
                    position: relative;
                    
                    &-background-img img {
                        object-fit: cover;
                        width: 100%;
                        filter: blur(4px);
                        -webkit-filter: blur(4px);
                        overflow: hidden;
                        position: relative;
                    }
                    &-main-img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-66.6%,-50%);
                        z-index: 2;
                        img {
                            
                        }
                    }
                    img {
                        width: 100%;
                    }
                }
                &-bottom {
                    padding: 4% 5%;
    
                    .title-text {
                        clear: both;
                        min-height: 2.5em;

                        .text-align-left {
                            float: left;
                        }
                        .text-align-right {
                            float: right;
                        }
                    
                        @include small_phone {
                            clear: unset !important;
                            > * {
                                float: unset !important;
                            }
                        }
                    }
    
                    .desc {
                        margin-bottom: 3%;
                        p {
    
                        }
                    }
                    .mail {
                        margin-bottom: 2%;
                        text-decoration: none;
                        color: $black;
                        i {
                            font-size: 110%;
                            margin-right: 2%;
                        }
                        p {
                            display: inline-block;
                            font-weight: 600;
                            letter-spacing: 0;
                        }
                    }
                    .read-more {
                        padding: 5% 0 3%;
                        > * {
                            display: flex;
                            justify-content: center;
                        }

                        .cta-btn3{
                            align-items: center;
                        }

                        p {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}