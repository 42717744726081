// BLUE
$deepblue: #06134D;
$deepblue_op80: #06134db6;
$deepblue_2: #030E40;
$darkblue: #112479;
$normalblue: #0870A7;
$normalblue2: #1184c2;
$lightblue: #18A0FB;
$lightlightblue: #b6dff5;
$purpleblue: #5271FF;
$customlightblue: #f2faff;


//CYAN
$cyan: #11A2AF;

//WHITES:
$white: rgba(255,255,255,1);
$white_op90: rgba(255,255,255,0.9);
$white_op80: rgba(255,255,255,0.8);
$white_op70: rgba(255,255,255,0.7);
$white_op60: rgba(255,255,255,0.6);
$white_op50: rgba(255,255,255,0.5);
$white_op40: rgba(255,255,255,0.4);
$white_op30: rgba(255,255,255,0.3);
$white_op20: rgba(255,255,255,0.2);
$white_op10: rgba(255,255,255,0.1);
$white_op0: rgba(255,255,255,0.0);

//BLACK
$black: black;
$black_op60: rgba(0,0,0,0.6);
$black_op50: rgba(0,0,0,0.5);
$black_op40: rgba(0,0,0,0.4);
$black_op30: rgba(0,0,0,0.3);
$black_op20: rgba(0,0,0,0.2);
$black_op10: rgba(0,0,0,0.1);
$black_op05: rgba(0,0,0,0.05);
$black_op01: rgba(0,0,0,0.01);