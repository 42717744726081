//WIDTHS 

$s-phone-width: 350px;
$m-phone-width: 480px;
$tablet-width: 767px;
$small_desktop-width: 1200px;
$desktop-width: 1500px;

@mixin small_phone {
    @media (max-width: #{$s-phone-width}) {
        @content;
    }
}

@mixin medium_phone {
    @media (max-width: #{$m-phone-width}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin small_desktop {
    @media (max-width: #{$small_desktop-width}) {
      @content;
    }
  }

@mixin desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

//HEIGHTS
$small-height: 480px;
$medium-height: 767px;
$large-height: 1200px;

@mixin small-height {
  @media (max-height: #{$small-height}) {
      @content;
  }
}

@mixin medium-height {
  @media (max-height: #{$medium-height}) {
      @content;
  }
}

@mixin large-height {
  @media (max-height: #{$large-height}) {
      @content;
  }
}

//ON MOBILE
@mixin onMobile {
  @media (pointer:none), (pointer:coarse) {
    @content;
  }
}
