html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", "Roboto";
}

body {
    font-family: 'Montserrat', serif;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}

ul, li {
list-style: none;
}

a {
color: black;
text-decoration: none;
}