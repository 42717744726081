@import '../../sass/abstracts/';

.body-g-body {
    position: relative;
    z-index: 8;

    .body-g {
        margin: 80px auto;
        max-width: $normal_max_width;
        width: 91.666vw;
    
        

        .top {
            position: relative;
            img {
                width: 100%;
                aspect-ratio: 5 / 1;
                object-fit:cover;
                border-radius: 5px;
            }
            .title {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);


                div {
                    border-radius: 10px;
                    padding: 10px 20px;
                    

                    h3 {
                        

                        text-align: center;
                    }
                }
                
            }   
        }
        .bottom {
            display: flex;
            justify-content:space-between;

            @include tablet {
                flex-direction: column;
            }

            &-col {
                flex: 1 0 30%;
                margin-top: 2%;

                p {
                    line-height: 1.5em;
                }
            }

            &-col ~ .bottom-col {
                margin-left: 5%;
                @include tablet {
                    margin-left: unset;
                }
            }
        }
    }

    &:target::before {
        content: '';
        display: block;
        height: 65px;
        margin-top: -65px;

        @include tablet {
            height: 40px;
            margin-top: -40px;
        }

        @include medium_phone {
            height: 30px;
            margin-top: -30px;
        }
    }
}