@import "../../../sass/abstracts/";

.media-page {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    padding-top: 1%;
    

    @include tablet {
        margin-top: 75px;
    }
    @include medium_phone {
        margin-top: 50px;
    }

    hr {
        margin-bottom: 5%;
    }

    img {
        max-height: 600px;
        object-fit: none;
    }

    &-box {
        max-width: $normal_max_width;
        width: 91.666vw;
        display: flex;
        flex-direction: column;
        position: relative;


        &-top {
            position: absolute;   
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);  
            text-align: center;

            h1 {
                letter-spacing: 0em;
                font-weight: 400;
            }
            p {
                font-size: 1.25em;
            }
            
        }
        &-bottom {
            position: absolute;   
            top: 70%;
            left: 50%;
            transform: translate(-50%,-50%);  
            text-align: center;

            @include tablet {
                top: 75%;
            }

            @include medium_phone {
                top: 80%;
            }
            @include small_phone {
                top: 85%;
            }

            .icons {
                display: flex;
                justify-content: center;
                margin: 0;
                a {
                    color: $black;
                    svg, path {
                        height: 2em;
                        margin: 0 1em;
                    }
                }
            }
        }
    }
}

.media-news {
    max-width: 1520px;
    width: 83.333vw;
    margin: 40px auto;
    @include tablet {
        width: 91.6666vw;
    }

    &-title {
        text-align: center;
        h2 {
            font-weight: 400;
            margin-bottom: 10px;
        }
    }
    &-body {
        width: 95.888%;
        margin: 40px auto 160px;

        @include tablet {
            .reorder {
                flex-direction: column-reverse;
            }
            
        }
        
        @include medium_phone {
            width: 100%;
        }

        &-row {
            margin: 20px 0 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include tablet {
                flex-wrap: wrap;
            }
            &::after {
                content: "";
                width: 2.0833%;
                margin-left: 2.0833%;
                border-top: 2px solid $black_op50;
                border-bottom: 2px solid $black_op10;
            }
            &::before {
                content: "";
                width: 2.0833%;
                margin-right: 2.0833%;
                border-top: 2px solid $black_op50;
                border-bottom: 2px solid $black_op10;
            }
            @include tablet {
                &::after,
                &::before {
                    content: unset;
                }
            }

            &-col {
                width: 100%;
                position: relative;
                
                img {
                    width: 100%;
                    margin: 10px 0;

                    @include tablet {
                        max-height: 10vh;
                        object-fit: cover;
                        @supports (aspect-ratio: 1) {
                            aspect-ratio: 4/1;
                            max-height: unset;
                        }
                    }
                }
                .news {
                    border-left: 1px solid $lightblue;

                    margin: 0;

                    &-info {
                        margin-left: 5%;
                        h4 {
                            margin-bottom: 2%;
                        }
                        p {
                            margin-bottom: 2%;
                        }
                        .cta-btn2 {
                            padding: 12px 0px;
                            border: 1px solid $black;
                            margin-top: 4%;
                        }
                    }
                }

                .date {
                    position: absolute;
                    bottom: 5%;
                    right: 1%;
                    color: white;
                }

                
            }
            &-col ~ .media-news-body-row-col {
                margin-left: 10%;
                @include tablet {
                    margin-left: 0;
                }
            }
        }
        .divider {
            margin: auto;
            text-align: center;
            p {
                text-align: center;
            }
        }
    }
    
}