@import "../../../sass/abstracts/";
.products,
.product-page {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    padding-top: 3%;
    border-top: 1px solid $black_op20;

    @include tablet {
        margin-top: 75px;
    }
    @include medium_phone {
        margin-top: 50px;
    }

    .main {
        max-width: $normal_max_width;
        width: 91.666%;
        display: flex;

        @include small_desktop {
            width: 95.888%;
        }

        @include tablet {
            flex-direction: column;
        }

        .left {
            flex: 1 1 25%;
            min-width: 18ch;

            ul {
                margin-right: 20%;
                list-style: none;
                box-shadow: 0 0 20px 0 $black_op20;
                padding: 10px 0 100%;
                border-radius: 2%;
                white-space: nowrap;
                overflow: auto;
                position: relative;

                @include small_desktop {
                    margin-right: 5.88%;
                }
                @include tablet {
                    padding: 10px 0;
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                a {
                    text-decoration: none;
                    color: $black;

                    @include tablet {
                        display: inline;
                    }
                    li {
                        text-align: center;
                        padding: 16px 6px;

                        @include tablet {
                            display: inline-block;
                            text-align: center;
                            padding: 6px 16px;
                        }
                        p {
                            font-weight: 500;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        &:hover {
                            background-color: $black_op10;
                        }
                    }
                }
                .mobile-swipe-arrow {
                    display: none;

                    @include tablet {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 5%;
                        transform: translate(-50%, -50%);
                        -webkit-animation: arrowSwiperAnimation 15s linear
                            infinite;
                        -moz-animation: arrowSwiperAnimation 15s linear infinite;
                        animation: arrowSwiperAnimation 15s linear infinite;
                    }
                }
            }
        }
        .right {
            flex: 1 1 75%;

            .head-img {
                img {
                    width: 100%;
                    object-fit: cover;

                }
            }
            .title-box {
                h1 {
                    margin-top: 0.1em;
                    margin-bottom: 0.2em;
                    font-weight: 600;
                }
                p {
                    margin-bottom: 1em;
                    line-height: 1.75em;
                }

                #extra-img {
                    width: 100%;
                }
            }
            .product {
                .title {
                    h3 {
                        margin-bottom: 0.5em;
                        font-weight: 400;
                    }
                    h5 {
                        font-weight: 700;
                    }
                }
                &-list {
                    display: flex;
                    flex-wrap: wrap;

                    @supports (gap: 1em) {
                        gap: 5%;
                    }
                    &-box {
                        flex: 0 1 30%;
                        text-decoration: none;
                        color: $black;
                        margin-bottom: 4%;

                        @include small_desktop {
                            flex: 0 1 47.5%;
                        }

                        @include small_phone {
                            flex: 0 1 100%;
                        }

                        .img {
                            img {
                                width: 100%;
                                // aspect-ratio: 1 / 1;
                                object-fit: cover;
                            }

                            margin-bottom: 2%;
                        }

                        hr {
                            width: 100%;
                            margin-bottom: 4%;
                        }
                        .bottom-text {
                            clear: both;

                            .text-align-left {
                                float: left;

                                font-weight: 600;
                            }
                            .text-align-right {
                                float: right;
                                font-weight: 500;
                            }

                            @media (max-width: 580px) {
                                h5,
                                p {
                                    font-size: 14px;
                                }
                            }
                            @include medium_phone {
                                h5,
                                p {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.products {
    .right {
        .head-img {
            img {
                @supports (aspect-ratio: 5 / 1) {
                    aspect-ratio: 5 / 2;
                }
            }
        }
    }
}
