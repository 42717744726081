@import '../../sass/abstracts/';

.body-f-body {
    position: relative;
    z-index: 8;
    margin: 200px auto;

    .body-f {
        margin: 80px auto;
        max-width: $full_max_width;
        width: 91.666vw;
    
        display: flex;
        align-items: center;
        justify-content:space-around;
            
        h3 {
            font-weight: 400;
            text-align: center;
        }

        .btn-center {
            display: flex;
            justify-content:space-evenly;
            
            
            margin: 20px 0 40px;

            

            @include tablet {
                margin: 17px 0 30px;
                
            }
        
            @include medium_phone {
                margin: 15px 0 20px;
                min-width: 66vw;
            }
            @include small_phone {
                margin: 13px 0 17px;
                min-width: 91.6vw;
            }

            @include tablet {
                flex-wrap: wrap;
            }
            .cta-btn1 {
                max-width: unset;
                margin: 5px 4px !important;
                
                @include small_desktop {
                    width: 20vw;
                }
                @include medium_phone {
                    width: unset;
                }

                &:hover {
                    margin: 0;
                }
            }
        }

        

        .icons {
            display: flex;
            justify-content: center;
            margin: 0;
            a {
                color: $black_op20;
                transition: 0.3s ease-in-out;
                &:hover {
                    color: $lightblue;       
                }

                svg, path {
                    height: 2em;
                    margin: 0 12px;


                    @include tablet {
                        font-size: 1.5em;
                        margin: 0 10px;
                    }
                
                    @include medium_phone {
                        font-size: 1.25em;
                        margin: 0 8px;
                    }
                    @include small_phone {
                        font-size: 1em;
                        margin: 0 6px;
                    }
        
                    
                }
                
            }
        }
    }
}