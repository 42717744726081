@import "../../../sass/abstracts/";
@import "../AboutUsPage/about-us-page.scss";

.om-oss-page {
    .title-var1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        color: $white;
        background-color: $deepblue_op80;
        padding: 8px 0px;
        border-radius: 10px;

        h1 {
            text-shadow: 0 0 38px $black;
            
        }
        p {
            margin: 5% 0;
            line-height: 1.5em;
            text-shadow: 0 0 5px $black;

            @include medium_phone {
                width: 91.666vw;
            }
        }

        > * {
            margin-bottom: 20px;
        }
    }
}