@import "../../../sass/abstracts/";

.contact-head {
    width: 100%;
    z-index: 1;
    position: relative;
    height: 30vh;
    max-width: 100%;
    
    display: block;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;

    @include large-height {
        height: 50vh;
    }

    .img {
        height: 50vh;
        width: 100vw;

        img {
            object-fit: cover;
            width: 100vw;
            filter: grayscale(30%);
            -webkit-filter: grayscale(30%);

            @include medium-height {
                width: 100vw;
            }
        }
    }
    .title {
        position: absolute;
        top: 66%;
        left: 50%;
        transform: translate(-50%,-50%);

        padding: 12px 20px;
        background-color: $lightlightblue;
        border-radius: 5px;
    }
}

.contact {
    margin: auto;
    max-width: $normal_max_width;
    .main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @supports (grid: 1em) {
            grid: 1em;
        }

        &-left {
            flex: 1 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0 50px;
            width: 100%;
            

            .contact-box {
                min-width: 83.333%;
                padding: 2% 1%;
                background-color: $lightlightblue;
                border-radius: 10px;

                

                h4 {
                    margin-left: 10px;
                    margin-bottom: 3%;
                    font-weight: 300;
                    text-transform: uppercase;
                }
                form {
                    margin: 0 30px;
                    font-size: 100%;

                    input, input::-webkit-input-placeholder {
                        display: block;
                        font: inherit;
                        margin: 10px 0;
                        width: 100%;
                        padding: 10px 12px;
                        font-size: 90%;
                        
                    }
                    textarea, textarea::-webkit-input-placeholder {
                        resize: none;
                        width: 100%;
                        font: inherit;
                        padding: 10px 12px;
                        margin-bottom: 10px;
                        font-size: 90%;
                        overflow: hidden;
                    }
                    button {
                        border: 0;
                        color: inherit;
                        font: inherit;
                        cursor: pointer;
                        outline: inherit;

                        p, i {
                            color: $white;
                        }
                    }
                    input, textarea, input:focus-visible, textarea:focus-visible, button {
                        font-size: 100%;
                        outline: none;
                        border: none;
                        border-radius: 10px;
                        margin-bottom: 5%;
                    }
                    input:focus, textarea:focus {
                        box-shadow: 0 0 10px 0 $black_op20;
                    }
                }   
                
            }
        }
        &-right {
            flex: 1 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 80px 0 50px;

            @include medium_phone {
                margin: 2px;
            }

            .contact-box {
                min-width: 83.333%;

                @include tablet {
                    width: 91.66%;
                    margin: auto;
                }
                h4 {
                    margin-bottom: 5%;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                div, a {
                    margin-bottom: 10%;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: $black;

                    svg, path {
                        height: 2em;
                        margin-right: 4%;
                    }
                    span {
                        display: inline-block;
                        h5 {
                            margin-bottom: 5px;
                            font-weight: 600;
                        }
                        p {

                        }
                    }
                }
                .location {
                    margin-top: 40px;
                    padding: 10px 12px;
                    border-radius: 10px;
                    background-color: $lightlightblue;
                    max-width: 600px;

                    iframe {
                        width: 100%;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}