@import '../../sass/abstracts/';

//BODY D

.light-box {
    transition: 3s ease-out;
    box-shadow: 100vw 0 150px 56px $white, -100vw 0 150px 56px $white, inset 200px 0 150px -200px $white, inset -200px 0 150px -200px $white;
    -webkit-box-shadow: 100vw 0 150px 56px $white, -100vw 0 150px 56px $white, inset 200px 0 150px -200px $white, inset -200px 0 150px -200px $white;

    @include small_desktop {
        box-shadow: 100vw 0 90px 21px $white, -100vw 0 90px 21px $white, inset 100px 0 90px -100px $white, inset -100px 0 90px -100px $white;
        -webkit-box-shadow: 100vw 0 90px 21px $white, -100vw 0 90px 21px $white, inset 100px 0 90px -100px $white, inset -100px 0 90px -100px $white;
    }

    @include tablet {
        box-shadow: 100vw 0 70px 18px $white, -100vw 0 70px 18px $white, inset 80px 0 70px -80px $white, inset -80px 0 70px -80px $white;
        -webkit-box-shadow: 100vw 0 70px 18px $white, -100vw 0 70px 18px $white, inset 80px 0 70px -80px $white, inset -80px 0 70px -80px $white;
    }

    @include medium_phone {
        box-shadow: 100vw 0 45px 13px $white, -100vw 0 45px 13px $white, $white, inset 50px 0 45px -50px $white, inset -50px 0 45px -50px $white;
        -webkit-box-shadow: 100vw 0 45px 13px $white, -100vw 0 45px 13px $white, inset 50px 0 45px -50px $white, inset -50px 0 45px -50px $white;
    }
    @include small_phone {
        box-shadow: 100vw 0 35px 8px $white, -100vw 0 35px 8px $white, inset 40px 0 35px -40px $white, inset -40px 0 35px -40px $white;
        -webkit-box-shadow: 100vw 0 35px 8px $white, -100vw 0 35px 8px $white, inset 40px 0 35px -40px $white, inset -40px 0 35px -40px $white;
    }

}

.body-d-body {
    position: relative;
    z-index: 8;

    padding-bottom: 80px;
    margin: 20px 0;

            @include tablet {
                padding-bottom: 40px;
                margin-bottom: 40px;
            }
        
            @include medium_phone {
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
            @include small_phone {
                padding-bottom: 13px;
                margin-bottom: 13px;
            }

    .body-d-title {
        max-width: $full_max_width;
        margin: auto;
        width: 91.66vw;
        

        &-box {
            max-width: 707px;

            h2 {
                font-weight: 400;
                line-height: 2em;
                letter-spacing: -0.015em;
                text-align: left;
    
            }
        }

        
    }

    .body-d {
        margin: 80px auto;
        margin-bottom: 0;
        max-width: $full_max_width;
        width: 83.333vw;
    
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        @supports (gap: 1vw) {
            gap: 1.5vw;
        }
        
        .body-d-col {
            width: 26.75vw;
            transition: 0.3s;
            margin-bottom: 10px;
            padding-bottom: 10px;
            max-width: 33%;

            @include tablet {
                max-width: unset;
            }

            img {
                width: 108px;
                height: 108px;
                border-radius: 100%;
                margin-bottom: 40px;
            }
            h4 {
                letter-spacing: -0.015em;
                margin-bottom: 24px;

            }
            p {
                line-height: 1.9em;
                letter-spacing: -0.015em;
                margin-bottom: 24px;
            }

            a {
                p {
                    color: $lightblue !important;
                }
                
            }

            &:hover {
                margin-top: -18px;
                box-shadow: 0 18px 0 0 $black_op20;
            }
        }

        
    }

    .buttons {
        width: 91.666vw;
        max-width: $full_max_width;
        margin: auto;
        

        div {
            display: flex; 
            justify-content: flex-end;

            @include tablet {
                .cta-btn1 {
                    margin-top: 0;
                }
            }

            
            
        }

        
    }

}
