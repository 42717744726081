@import '../../sass/abstracts/';

.body-c-body {
    margin: 30px auto;

    position: relative;
        z-index: 5;
    .body-c-title {
        text-align: center;

        h2 {
            font-weight: 400;
            line-height: 2em;
            letter-spacing: -0.015em;
        }
    }

    .body-c {
        margin: 80px auto;
        max-width: $full_max_width;
        width: 91.666vw;
    
        display: flex;
        align-items: center;
        justify-content:space-between;
    
        
    
        &-left {
            float: left;
            width: 50%;
            
            img {
                width: 100%;
                position: relative;
            }
            
            
        }
        &-right {
            float: right;
            width: 45%;
            padding-left: 5%;
            
            h4 {
                margin-bottom: 21px;
            }
            p > * {
                margin-bottom: 48px;
            }

            ul {
                margin-top: 40px;
                margin-bottom: 48px;
                list-style: none;
                li {
                    margin-bottom: 31px;
                    display: flex;
                    align-items: center;

                    img {
                        border-radius: 50%;
                        width: 44px;
                        height: 44px;
                    }

                    p {
                        color: #00000099;
                        display: inline-block;
                        margin-left: 30px;
                    }
                }
                
            }
        }
        .buttons {
            display: flex;

        }
    }
}