@import "../../../sass/abstracts/";

.partners-page {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    padding-top: 1%;

    @include tablet {
        margin-top: 75px;
    }
    @include medium_phone {
        margin-top: 50px;
    }

    &-box {
        max-width: $normal_max_width;
        width: 91.666vw;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-radius: 2%;

        &-container {
            border: 2px solid $black_op20;
            border-radius: 2%;
            overflow: hidden;

            &:hover img {
                opacity: 0;
            }

            img {
                
                width: 100%;
                filter: blur(2px);
                -webkit-filter: blur(2px);
                overflow: hidden;
                transition: 0.5s;
                border-radius: 2%;
                &:hover {
                    filter: blur(0px);
                    -webkit-filter: blur(0px);
                    opacity: 1;
                }
                margin-bottom: -5px;
                width: 100%;
                display: inline-block;
                
            }

            img ~ img {
                position: absolute;
                display: inline;
            }

            .img2 {
                left: 33%;
                &:hover {
                    left: 0;
                }
            }
            .img3 {
                left: 66%;
                &:hover {
                    left: 0;
                }
            }

            
        }
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 12px 20px;
            background-color: $lightlightblue;
            border-radius: 5px;
            opacity: 1;
            transition: 0.5s;
        }

        &:hover .title {
            opacity: 0;
        }
    }

    
}

.companies {
    display: block;
    margin: 80px auto;
    max-width: $normal_max_width;
    width: 83.333vw;


    h4 {
        margin: 80px auto;
        text-align: center;
        text-transform: uppercase;

    }

    &-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @supports (gap: 1em) {
            gap: 4em;
        }
        &-container {
            flex: 0 1 20%;
        }

        p {
            text-align: center;
        }
    }
}