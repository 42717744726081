@import '../../sass/abstracts/';

:root {
    --margin-hover: 20px;
    --margin-right: 45px;

    @include desktop {
        --margin-right: 40px;
    }
    @include small_desktop {
        --margin-right: 35px;
        --margin-hover: 15px;
    }
    @include tablet {
        --margin-right: 20px;
    }
    @include medium_phone {
        --margin-right: 10px;
        --margin-hover: 8px;
    }
    @include small_phone {
        --margin-right: 8px;
        --margin-hover: 5px;
    }
}

// BUTTON 1 AND 2
.cta-btn1, .cta-btn2 {
    padding: 20px 38px; 
    font-size: $p_large_FS;
    text-decoration: none;
    position: relative;
    border-radius: 10px;
    margin-top: 0;
    display: flex;
    transition: 0.33s;
    cursor: pointer;

    p {
        text-align: center;
        letter-spacing: -0.015em;
        width: 100%;
        transition: 0.5s;
    }
    svg {
        
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translate(-50%,-50%);

        height: 1rem;
        transition: 0.5s;
    }

    &:hover {
        
        box-shadow: -10px 0px 10px 0px $black_op20;
    }
    &:hover > * {
        margin-left: 10px;
        margin-right: -10px;
        text-shadow: -20px 0px 4px $black_op10;
    }

    @include desktop {
        &:hover {
            margin-left: 20px;
            margin-right: 20px;
            box-shadow: -10px 0px 10px 0px $black_op20;
        }
    }
    @include small_desktop {
        padding: 18px 35px; 
    }
    @include tablet {
        padding: 16px 30px; 
    }
    @include medium_phone {
        padding: 16px 20px; 
    }
    
}

// BUTTON 1
.cta-btn1 {
    background-color: $normalblue;
    color: $white;
    width: 304px;
    margin-right: var(--margin-right);

    

    &:hover {
        margin-left: var(--margin-hover);
        margin-right: calc(var(--margin-right) - var(--margin-hover));
    }

    @include desktop {
        flex-basis: 100%;
        flex-wrap: wrap;
    }   
    
    @include small_phone {
        width: 100%;
    }
}
.cta-btn1-var1 {
    border: 2px solid $white;
    background-image: linear-gradient(90deg, $normalblue, $normalblue2);

    &:hover {
        border: 2px solid $lightblue;
    }
}
.cta-btn1-var2 {
    margin-top: 48px;
    background-color: $darkblue;
    max-width: 304px;
}

.cta-btn-nospace {
    margin-right: 0;
    &:hover {
        margin-right: -20px;
    }
}

// BUTTON 2

.cta-btn2 {
    color: $black;
    border: 2px solid $black;
    width: 204px;

    margin-right: 0px;

    &:hover {
        margin-left: var(--margin-hover);
        margin-right: calc(0px - var(--margin-hover));
    }
    
    @include desktop {
        flex-basis: 80%;
    }
    @include tablet {
        border: 1px solid $black;
        text-align: center;
        
    }
}

.cta-btn2-var1 {
    background-color: $white_op70;
    border: none;
    width: 180px;
    background: linear-gradient(130deg, $white_op10, $white, $white_op10);
    background-size: 200% 200%;

    -webkit-animation: colorGradAnimation 5s ease 2s infinite;
    -moz-animation: colorGradAnimation 5s ease 2s infinite;
    animation: colorGradAnimation 5s ease 2s infinite;
}

@-webkit-keyframes colorGradAnimation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes colorGradAnimation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes colorGradAnimation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}


.cta-btn2-var2 {
    margin-top: 48px;

    @include desktop {
        max-width: 200px;
    }
    @include small_desktop {
        border: 1px solid $black;
        
    }
}
.cta-btn2-with-margin {
    margin-right: var(--margin-right);
    
    &:hover {
        margin-left: var(--margin-hover);
        margin-right: calc(var(--margin-right) - var(--margin-hover));
    }
}


.cta-btn3 {
    color: #18A0FB;
    text-decoration: none;

    p {
        font-family: Montserrat;
        font-weight: 700;
        width: 100%;
        max-width: fit-content;
    }

    ::after {
        content: '';
        width: 0%;
        height: 2px;
        background: $lightblue;
        display: block;
        margin-top: -1px;
        transition: 0.33s ease-in-out;
    }
    :hover::after {
        width: 100%;
    }
}

