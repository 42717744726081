@import '../../sass/abstracts/';

// .body-c {
//     transform: scale(0,1);
//     transition: transform ease-in-out 0.3s;

//     @media (prefers-reduced-motion) {
//         transform: scale(1,1);
//     }
// }

// .body-e {
//     opacity: 0;
//     transition: ease-in-out 0.5s;

//     @media (prefers-reduced-motion) {
//         opacity: 1;
//     }
// }

.body-c-body,  .body-e-body { 
    overflow:hidden;
}

.body-a img, .body-b img, .body-c img {
    border-radius: 5px;
}

.js-white-boxshadow:hover {
    box-shadow: 0 18px 0 0 $white_op20 !important;
}


// --- MORE MEDIA QUERIES ---
@include desktop {
    .body-a, .body-b, .body-c, .body-d, .body-e, .body-f, .body-f-body, .body-g, .body-g {
        margin: 70px auto !important;
    }
}

@include small_desktop {
    .body-a, .body-b, .body-c, .body-d, .body-e, .body-f, .body-f-body, .body-g {
        margin: 60px auto !important;
    }
}



@include tablet {
    .body-a, .body-b, .body-c, .body-d, .body-e, .body-f, .body-f-body, .body-g {
        margin: 50px auto;
        flex-direction: column;
        &-left, &-right, &-col {
            width: 100% !important;
            margin-right: 0 !important; 
            margin-left: 0 !important; 
            padding-left: 0 !important;
        }
    }
}

@include medium_phone {
    .body-a, .body-b, .body-c, .body-d, .body-e, .body-f, .body-f-body, .body-g {
        margin: 40px auto !important;
    }
}
@include small_phone {
    .body-a, .body-b, .body-c, .body-d, .body-e, .body-f, .body-f-body, .body-g {
        margin: 30px auto !important;
    }
}