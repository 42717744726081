@import '../../../../sass/abstracts/';


.head {
    width: 100%;
    z-index: 1;
    position: relative;
    

    

    max-width: 100%;
    display: block;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;

    height: $head_height;

    @include large-height {
        height: 100vh;
    }

    .img {
        
        
        img {
            width: 100%;
            margin-left: 0%;
            object-fit: contain;
            position: relative;
            

            @include large-height {
                position: fixed; 
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            @media (pointer:none), (pointer:coarse) {
                transition: 0.1s;
            }
            
        }

        .layer {
            background-image: linear-gradient(45deg, $white_op60, $white_op0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        
        .white-cover {
            background-color: $white;
            min-height: 200%;
            min-width: 100%;
            position: absolute;
            top: $head_height;
            z-index: 1;
            
            @include large-height {
                top: 100vh;
            }
            
            @supports (clip-path: none) {
                display: none;
            }
        }
    }
    
    

    .arrow-left, .arrow-right {
        position: absolute;
        top: 50%;
        color: $darkblue;
        transition: 0.3s;

        svg {
            height: 4em;
            cursor: pointer;
        }
        @include tablet {
            display: none;
        }

    }
    .arrow-left {
        left: 4.1666vw;
        &:hover {
            left: 3.9vw;
        }
    }
    .arrow-right {
        right: 4.166vw;

        &:hover {
            right: 3.9vw;
        }
    }


    .text-box {
        position: absolute;
        top: 60%;
        left: 12.5vw;
        transform: translateY(-50%);
        max-width: 1200px;
        //max-width: 45.888vw;
        width: 75%;

        .slogan-box {
            
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            
            
            
            font-size: $h1_fontsize;
            

            background-color: transparent;

            h1 {
                padding: 0px 20px 5px;
                color: $deepblue;
                
                border-left: 4px solid rgba($normalblue,1);

                @supports (background-clip: text) {
                    background-image: linear-gradient(270deg, $darkblue, $lightblue);
                    background-clip: text;
                    color: transparent;
                }
                @supports (-webkit-background-clip: text) {
                    background-image: linear-gradient(270deg, $darkblue, $lightblue);
                    -webkit-background-clip: text;
                    color: transparent;
                }

                

                
            }
            p {
                color: $black;
                line-height: 1.8em;
                width: 88.888%;
                
                @supports (background-clip: text) {
                    background-image: linear-gradient(90deg, $deepblue_2, $black);
                    background-clip: text;
                    color: transparent;
                }
                @supports (-webkit-background-clip: text) {
                    background-image: linear-gradient(90deg, $deepblue_2, $black);
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
        }

        .buttons {
            display: flex;
            align-items: center;

            margin-top: 2.1em;
        }

        @include small_desktop {
            max-width: 700px;
        }
        @include tablet {
            left: 2.6vw;
            width: 91.666vw;
        }
    }

    .dots {
        position: absolute;
        text-align: center;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);

        cursor: default;

        i {
            color: $white;
            font-size: 0.5em;
        }
    }
}