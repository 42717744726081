@import "../../../sass/abstracts/";

.om-oss-page {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    padding-top: 1%;

    @include tablet {
        margin-top: 75px;
    }
    @include medium_phone {
        margin-top: 50px;
    }

    &-box {
        max-width: $normal_max_width;
        width: 91.666vw;
        display: flex;
        flex-direction: column;
        

        &-top {
            flex: 1 1 25%;
            min-width: 18ch;
            

            ul {
                
                margin-right: 20%;
                list-style: none;
                border-top: 1px solid $black_op20;
                border-radius: 2%;
                white-space: nowrap;
                overflow: auto;
                position: relative;

                padding: 1% 0;
                margin-right: 0;
                margin-bottom: 20px;

                justify-content: center;
                display: flex;

                @include medium_phone {
                    justify-content: unset;
                }

                a {
                    text-decoration: none;
                    color: $black;
                    display: inline;
                    li {
                        
                        text-align: center;
                        display: inline-block;
                        text-align: center;
                        padding: 6px 2em;
                        border-radius: 5px;
                        
                        p {
                            font-weight: 400;
                        }
                        &:hover {
                            background-color: $black_op10;
                        }
                        
                    }
                    .current-page {
                        background-color: $black_op05;
                    }
                }
                .mobile-swipe-arrow {
                    display: none;
    
    
                    @include medium_phone {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 5%;
                        transform: translate(-50%,-50%);
                        -webkit-animation: arrowSwiperAnimation 15s linear infinite;
                        -moz-animation: arrowSwiperAnimation 15s linear infinite;
                        animation: arrowSwiperAnimation 15s linear infinite;
                    }
                }
            } 
        }
        &-bottom {
            position: relative;
            border: 2px solid $black_op20;
            border-radius: 2%;  


            img {
                width: 100%;
                filter: blur(4px);
                -webkit-filter: blur(4px);
                overflow: hidden;
                transition: 0.5s;
                border-radius: 2%;
                &:hover {
                    filter: blur(0px);
                    -webkit-filter: blur(0px);
                }
                margin-bottom: -5px;
            }
            .title {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }

        &-bottom-special-blend {
            background-image: url("./om_oss_1.jpg");
            background-size: 100%;
            margin: auto;

            

            h1 {
                color: black;
                background-color: white;
                mix-blend-mode: screen;
                padding: 0 20px;
               

                text-transform: uppercase;
                width: 100%;
                height: 100%;
                
                font-weight: 900;
                text-align: center;
                
                font-size: 20ch;

                padding: 5% 0;
                text-shadow: 0 0 10px $black;
                
                animation: text-shadow 4s ease-in 1s infinite;

                @keyframes text-shadow {
                    0% {text-shadow: 0 0 10px $black;}
                    25% {text-shadow: 0 0 0px $black;}
                    50% {text-shadow: 0 0 1px $black;}
                    75% {text-shadow: 0 0 15px $black;}
                  }

                  @include desktop {
                    font-size: 20vw;
                    line-height: 20vw;
                  }
            }
            hr {
                width: 10%;
                margin: auto;
            }
        }
    }
}

.about-us {
    margin: auto;

    > * {
        > * {
            max-width: $normal_max_width;
        }
    }
}